import Vue from 'vue';
import Vant, {
	Dialog
} from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import {
	parseDate
} from '../../../../utils/convert.js'

export default {
	name: "gnrhMedicalInput",
	data() {
		return {
			token: '',
			patientUuid: '',
			show: false,
			date: '',
			minDate: new Date(2018, 0, 1),
			maxDate: new Date,
			issubmit: false,

			hosList: [],

			screenHeight: document.body.clientHeight, // 这里是给到了一个默认值
			originHeight: document.body.clientHeight, // 默认高度在watch里拿来做比较
			isOriginHei: true, // 这个属性是固定定位按钮的显隐开关

			gnrhMedicalForm: {
				// userUuid: '',
				visitDate: '',
				hospitalName: '',
				hospitalCode: '',
				commit: '',
				treatment: '',
				verificationResult1: '',
				verificationResult2: '',
				verificationResult3: '',
				verificationResult4: '',
				verificationResult5: '',
				verificationResult6: '',
				verificationResult7: ''
			},
		}
	},
	created() {
		this.loading = false;
		console.log(this.maxDate)
	},
	mounted() {
		this.token = this.$route.query.token;
		this.patientUuid = this.$route.query.patientUuid;
		if (this.patientUuid === null || this.patientUuid === '') {
			Dialog.alert({
				title: '提示',
				message: '抱歉，您还未登录，请先登录再进行操作。',
			});
			return;
		}

		// let self = this;
		// window.onresize = () => {
		//     return (() => {
		//         self.screenHeight= document.body.clientHeight
		//     })()
		// }
	},
	// watch: {
	//     screenHeight(val) {
	//         if(this.originHeight-val > 50) {
	//             // 键盘弹出
	//             this.isOriginHei = false
	//         }else{
	//             // 键盘收起
	//             this.isOriginHei = true
	//         }
	//     }
	// },
	// beforeDestroy() {
	//     window.onresize = null;
	// },
	methods: {
		inputBlur() {
			document.body.style.marginBottom = '0';
		},
		inputFocus(item) {
			console.log(item);
			if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
				document.body.style.marginBottom = '220px';
			}
			this.activeElementScrollIntoView();
		},
		activeElementScrollIntoView() {
			const activeEl = document.activeElement;
			if (
				activeEl.tagName === 'INPUT' ||
				activeEl.tagName === 'TEXTAREA'
			) {
				window.setTimeout(() => {
					if ('scrollIntoView' in activeEl) {
						activeEl.scrollIntoView();
					} else {
						activeEl.scrollIntoViewIfNeeded();
					}
				}, 100);
			}
		},
		querySearch(queryString, cb) {
			if (queryString.length > 0) {
				this.hosList = [];
				this.$axios.get("/api/api/hospital/searchHospital/" + queryString).then(res => {
					if (res.data.code === 200) {
						let oneHos = {};
						res.data.data.forEach(d => {
							oneHos = {
								value: d.name,
								code: d.code
							};
							this.hosList.push(oneHos);
						});
						cb(this.hosList);
					}
				});
			}
		},
		handleSelect(item) {
			if(item.value) {
				this.gnrhMedicalForm.hospitalName = item.value;
				this.gnrhMedicalForm.hospitalCode = item.code;
			}
		},
		onConfirm(d) {
			this.show = false;
			this.date = parseDate(d);
		},
		onSubmit() {
			let that = this;
			that.issubmit = true;
			that.$set(that.gnrhMedicalForm, "userUuid", that.patientUuid);
			that.$set(that.gnrhMedicalForm, "visitDate", that.date);

			console.log("that.gnrhMedicalForm", JSON.stringify(that.gnrhMedicalForm));

			that.$axios({
				method: "post",
				url: "/api/api/patientGnrhMedicalRecord/create",
				data: that.gnrhMedicalForm
			}).then(res => {
				if (res.data.code === 200) {
					that.$message({
						message: "保存成功！",
						type: "success",
						onClose: () => {
							that.issubmit = false;
							that.$router.push({
								path: "/gmlist",
								query: {
									patientUuid: that.patientUuid
								}
							});
						}
					});
				} else {
					that.$message({
						message: res.data.message,
						type: "error"
					});
				}
			});
		},
	}
}
